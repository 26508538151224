var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSection("business_address")
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.verify-order-accordion-1",
                  modifiers: { "verify-order-accordion-1": true },
                },
              ],
              staticClass: "accordion-header",
              class: { disabled: _vm.sectionIsDisabled("business_address") },
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("set-as-current")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", { staticClass: "header-left" }, [
                    _c("h4", [_vm._v("\n          Address\n        ")]),
                    _c(
                      "h5",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sectionIsComplete("business_address"),
                            expression: "sectionIsComplete('business_address')",
                          },
                        ],
                      },
                      [
                        _c("check-circle", {
                          staticClass: "check-circle",
                          attrs: { width: "20", height: "20" },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.businessAddressHeader) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("feather-icon", { attrs: { type: "chevron-right" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              ref: "verify-order-accordion-1",
              attrs: {
                id: "verify-order-accordion-1",
                accordion: "verify-order-accordion",
                role: "tabpanel",
                visible: _vm.currentSection === "business_address",
              },
            },
            [
              _c("b-card-body", [
                _c("p", { staticClass: "mt-3 pr-2 pl-2" }, [
                  _vm._v(
                    "\n        Keep your residential and personal information more secure and private with our address service.\n      "
                  ),
                ]),
                _vm.privateBusinessAddress
                  ? _c("div", { staticClass: "personal-address-container" }, [
                      _c("div", [
                        _c("div", { staticClass: "icon-container" }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  type: "house",
                                  width: "45",
                                  height: "45",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "address-container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "address-content" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.company?.name) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "address-content" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.privateBusinessAddress.line1) +
                                    " " +
                                    _vm._s(
                                      _vm.privateBusinessAddress?.line2 || ""
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "address-content" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.privateBusinessAddress.city) +
                                    ",\n                  " +
                                    _vm._s(
                                      _vm.privateBusinessAddress
                                        .state_province_region
                                    ) +
                                    "\n                  " +
                                    _vm._s(
                                      _vm.privateBusinessAddress.zip_postal_code
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "b-card-footer",
                {
                  staticClass: "accordion-footer",
                  attrs: { "footer-tag": "footer", role: "tab" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "upsell-button standard-verify-button-size button-upgrade",
                      attrs: {
                        variant: "primary",
                        "aria-label": "continue button",
                      },
                      on: { click: _vm.addBusinessAddressAndProceed },
                    },
                    [_vm._v("\n        Continue\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }