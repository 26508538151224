<template>
  <b-card
    v-if="showSection('business_address')"
    no-body
  >
    <b-card-header
      v-b-toggle.verify-order-accordion-1
      :class="{ disabled: sectionIsDisabled('business_address') }"
      header-tag="header"
      class="accordion-header"
      role="tab"
      @click.stop.capture="$emit('set-as-current')"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="header-left">
          <h4>
            Address
          </h4>
          <h5 v-show="sectionIsComplete('business_address')">
            <check-circle class="check-circle" width="20" height="20" />
            {{ businessAddressHeader }}
          </h5>
        </div>
        <feather-icon type="chevron-right" />
      </div>
    </b-card-header>
    <b-collapse
      id="verify-order-accordion-1"
      ref="verify-order-accordion-1"
      accordion="verify-order-accordion"
      role="tabpanel"
      :visible="currentSection === 'business_address'"
    >
      <b-card-body>
        <p class="mt-3 pr-2 pl-2">
          Keep your residential and personal information more secure and private with our address service.
        </p>

        <div v-if="privateBusinessAddress" class="personal-address-container">
          <div>
            <div class="icon-container">
              <div class="icon">
                <feather-icon type="house" width="45" height="45" />
              </div>
            </div>

            <div class="address-container">
              <div class="row">
                <div class="address-content">
                  <span>
                    {{ company?.name }}
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="address-content">
                  <span>
                    {{ privateBusinessAddress.line1 }} {{ privateBusinessAddress?.line2 || '' }}
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="address-content">
                  <span>
                    {{ privateBusinessAddress.city }},
                    {{ privateBusinessAddress.state_province_region }}
                    {{ privateBusinessAddress.zip_postal_code }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>

      <b-card-footer
        footer-tag="footer"
        class="accordion-footer"
        role="tab"
      >
        <b-button
          class="upsell-button standard-verify-button-size button-upgrade"
          variant="primary"
          aria-label="continue button"
          @click="addBusinessAddressAndProceed"
        >
          Continue
        </b-button>
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BusinessAddressSection',
  components: {
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
    CheckCircle: () => import('@images/ui/check-circle.svg'),
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('verifyOrder', [
      'currentSection',
      'sectionIsComplete',
      'sectionIsDisabled',
      'showSection',
      'privateBusinessAddress',
      'company',
      'showSection',
    ]),
    oneLineAddress() {
      if (!this.privateBusinessAddress.line1 || !this.privateBusinessAddress.city || !this.privateBusinessAddress.zip_postal_code) return ''
      return `${this.privateBusinessAddress.line1}${this.privateBusinessAddress.line2 ? ` ${this.privateBusinessAddress.line2}` : ''}, ${this.privateBusinessAddress.city},
        ${this.privateBusinessAddress.state_province_region} ${this.privateBusinessAddress.zip_postal_code}`
    },
    businessAddressHeader() {
      return this.sectionIsComplete('business_address') ? this.oneLineAddress : ''
    },
  },
  async mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions('verifyOrder', [
      'logVerifyOrderInteraction',
    ]),
    async addBusinessAddressAndProceed() {
      await this.logVerifyOrderInteraction('business-address-section-completed')
      this.$emit('completed')
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-container {
  float: left;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 7.5em;
}

.address-container {
  text-align: left;
  float: left;
  padding: 1em;
}

.personal-address-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 7.5em;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 0.3125em;
}

.icon-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3125em;
}

.address-content {
  flex: 1;
}
</style>
